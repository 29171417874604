import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
// import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// import { DragSource, DropTarget } from 'react-dnd';

const styles = theme => ({
  card: {
    // minWidth: 275,
    width:295,
    minHeight:150,
    backgroundColor: "white",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    // marginBottom: 12,
  },
  button: {
    margin: theme.spacing(1),
  },
});

/**
 * Implements the drag source contract.
 */
// const cardSource = {
//   beginDrag(props) {
//     props.onStartMoveDocument();
//     return {
//       Document: props.Document,
//       onDropDocument: props.onDropDocument,
//     };
//   },
//   endDrag(props, monitor, component) {
//     if (!monitor.didDrop()) {
//       props.onAbortMoveDocument();
//     }
//   }
// };

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
// const cardTarget = {
//   drop(props, monitor, component) {
//     // if (monitor.didDrop()) {
//     //   // If you want, you can check whether some nested
//     //   // target already handled drop
//     //   return;
//     // }
// 
//     // Obtain the dragged item
//     const documentCard = monitor.getItem();
//     documentCard.onDropDocument();
// 
//     // You can also do nothing and return a drop result,
//     // which will be available as monitor.getDropResult()
//     // in the drag source's endDrag() method
//     //return { moved: true };
//   },
//   hover(props, monitor, component) {
// 		if (!component)
//       return null;
//       
//     const documentCard = monitor.getItem();
// 		const dragRank = documentCard.Document.Rank;
// 		const hoverRank = props.Document.Rank;
// 
// 		// Don't replace items with themselves
//  		if (dragRank === hoverRank)
//  			return;
//  
//     props.onMoveDocument(documentCard.Document);
// 	},
// }

/**
 * Specifies the props to inject into your component.
 */
// function dragCollect(connect, monitor) {
//   return {
//     connectDragSource: connect.dragSource(),
//     isDragging: monitor.isDragging(),
//   };
// }

/**
 * Specifies which props to inject into your component.
 */
// function dropCollect(connect, monitor) {
//   return {
//     // Call this function inside render()
//     // to let React DnD handle the drag events:
//     connectDropTarget: connect.dropTarget(),
//     // You can ask the monitor about the current drag state:
//     //isOver: monitor.isOver(),
//     // isOverCurrent: monitor.isOver({ shallow: true }),
//     // canDrop: monitor.canDrop(),
//     // itemType: monitor.getItemType()
//   };
// }

const propTypes = {
  Tenant: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  // Injected by React DnD:
  // isDragging: PropTypes.bool.isRequired,
  //isOver: PropTypes.bool.isRequired,
  // connectDragSource: PropTypes.func.isRequired,
  // connectDropTarget: PropTypes.func.isRequired,
};

class TenantCard extends Component {
  render() {
    const { 
      // isDragging,
      //isOver,
      // connectDragSource,
      // connectDropTarget,
      classes, 
      Tenant 
    } = this.props;
    
    // let cardActions = null;
    // if (TenantDocumentCount.New > 0) {
    //   cardActions = (
    //     <CardActions style={{float:"right"}}
    //         // style={{ opacity: (isDragging) && 0 }}
    //         >
    //         <IconButton
    //           className={classes.button}
    //           aria-label="Clear Pending"
    //           title="Clear Pending"
    //           onClick={this.props.onClearPending}
    //           >
    //           <Icon>highlight_off</Icon>
    //         </IconButton>
    //       </CardActions>
    //   )
    // }

    return (// connectDropTarget(connectDragSource
      <div>
        <Card className={classes.card} 
          // style={{backgroundColor: (isDragging) && "gray" }}
          >
          <CardActionArea
            onClick={this.props.onCardAction}>
            <CardContent
              // style={{ opacity: (isDragging) && 0 }}
              >
              <Typography variant="h5" component="h2">
                {Tenant.CustomerName}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                {Tenant.AccountEmail}
              </Typography>
            </CardContent>
          </CardActionArea>
        
          {/* {cardActions} */}
          
        </Card>
      </div>
    // )
    );
  }
}

TenantCard.propTypes = propTypes;

// export default DropTarget('TenantCard', cardTarget, dropCollect)(DragSource('TenantCard', cardSource, dragCollect)(withStyles(styles)(TenantCard)));
export default withRouter(withStyles(styles, {withTheme: true})(TenantCard))