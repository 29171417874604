import API from './api'

export default {
	GetNextAvailableDocument(tenantKey, resolveCallback, rejectCallback, priorDocumentKeyOptional) {
		// Loads the first 'Pending' document for this user if exists.
		// Otherwise, the first 'New' document is marked 'Pending' for this user and returned.
		let params = { tenant: tenantKey };
		if (priorDocumentKeyOptional !== null) {
		  params = Object.assign(params, { ignoreDocumentKey: priorDocumentKeyOptional });
		}
		API.get("/documents", 
		  { 
		    params: params
		  }
		)
		  .then(resp => resolveCallback(resp))
		  .catch(err => rejectCallback(err));
	}
}