import React, { Component } from 'react';

import NoSsr from '@material-ui/core/NoSsr';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

// import FormHelperText from '@material-ui/core/FormHelperText';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
	  	variant="outlined"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
		  className={classNames(props.selectProps.classes.chip, {
		    [props.selectProps.classes.chipFocused]: props.isFocused,
		  })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square
    	style={{
        position: "static"
      }} 
      className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const styles = theme => ({
	textField: {
		// margin: theme.spacing(1),
	},
	formControl: {
		// margin: theme.spacing(1),
	},
	input: {
    display: 'flex',
    padding: 0,
    height:'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    // The following margin items are necessary for variants outlined/filled
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2) - 3,
    marginLeft: theme.spacing(2) - 2,
    /* These two properties, width:0 and overflow:hidden must remain to fix 
    an issue with the react-select component growing to infinity as users type in the input */
    width: 0,
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    // The following is necessary for variants outlined/filled
    zIndex: 3,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

// const options = [
// 	'Apple',
// 	'Apple Banana',
// 	'Avacado',
// 	'Banana',
// 	'Chocolate',
// 	'Strawberry',
// 	'Vanilla'
// ];
// 
// const filterOptions = (inputValue: string) =>
//   options.filter(v => v.toLowerCase().includes(inputValue.toLowerCase()))
//   	.map(v => ({value: v, label: v}));
// 
// const promiseOptions = inputValue =>
//   new Promise(resolve => {
//     setTimeout(() => {
//       resolve(filterOptions(inputValue));
//     }, 500);
//   });

const placeHolderChar = '\xA0';

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function GetRegexMaskFromCapturePointMask(capturePointMask) {
	//let sample = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	let rKeyboard = /[a-zA-Z0-9 ,./<>?;:"'`~!@#$%^&*()[\]{}_\-+=|\\]/;
	let rLetter = /[a-zA-Z]/;
	let rDigit = /[0-9]/;
	let rLetterOrDigit = /[a-zA-Z0-9]/;
	let regex = [];
	let required = [];
	let casingPositions = [];
	for (let i = 0, p = 0; i < capturePointMask.length; i++, p++) {
		switch (capturePointMask[i]) {
			case "&":
			regex.push(rKeyboard);
			required.push(true);
			break;
			case "C":
			regex.push(rKeyboard);
			required.push(false);
			break;
			case "L":
			regex.push(rLetter);
			required.push(true);
			break;
			case "?":
			regex.push(rLetter);
			required.push(false);
			break;
			case "0":
			case "#":
			regex.push(rDigit);
			required.push(true);
			break;
			case "9":
			regex.push(rDigit);
			required.push(false);
			break;
			case "A":
			regex.push(rLetterOrDigit);
			required.push(true);
			break;
			case "a":
			regex.push(rLetterOrDigit);
			required.push(false);
			break;
			case ">":
			casingPositions.push([p, ">"]);
			p--;
			break;
			case "<":
			casingPositions.push([p, "<"]);
			p--;
			break;
			case "\\":
			if (capturePointMask.length - 1 > i) {
				i++;
				regex.push(capturePointMask[i]);
				required.push(true);
			}
			break;			
			default:
			regex.push(capturePointMask[i]);
			required.push(true);
			break;
		}
	}
	return regex;
}

function GetModifiersFromCapturePointMask(capturePointMask) {
	let modifiers = [];
	for (let i = 0, p = 0; i < capturePointMask.length; i++, p++) {
		switch (capturePointMask[i]) {
			case ">":
			modifiers.push({position: p, modifier: ">"});
			p--;
			break;
			case "<":
			modifiers.push({position: p, modifier: "<"});
			p--;
			break;
			case "\\":
			if (capturePointMask.length - 1 > i) {
				i++;
			}
			break;			
			default:
			break;
		}
	}
	return modifiers;
}

function GetStaticCharactersFromCapturePointMask(capturePointMask) {
	let characters = [];
	for (let i = 0, p = 0; i < capturePointMask.length; i++, p++) {
		switch (capturePointMask[i]) {
			case ">":
			case "<":
			p--;
			break;
			case "\\":
			if (capturePointMask.length - 1 > i) {
				i++;
				characters.push({position: p, character: capturePointMask[i]})
			}
			break;			
			default:
			break;
		}
	}
	return characters;
}

class FieldInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			key: (new Date()).toString(),
			ValueChangeUpdateId: new Date(),
		};

		let { Field } = props;

		this.fieldId = "field_" + Field.UniqueId;
		//this.inputRef = React.createRef();
		//this.inputRef = null;

		this.NumericInput = props => {
			const { inputRef, ...other } = props;
			let maskConfig = { allowNegative: true, prefix: "" };
			let { Field } = this.props;

		  	// Currency Prefix
			if (Field.Type === "Currency") {
				Object.assign(maskConfig, {prefix: "$"});
				Field.DecimalPlaces = 2;
			}
		  	// Decimal Places
		 	if (typeof Field.DecimalPlaces !== "undefined" && Field.DecimalPlaces != null)
				Object.assign(maskConfig, {allowDecimal: true, decimalLimit: Field.DecimalPlaces});

			const numericMask = createNumberMask(maskConfig);
			return (
				<MaskedInput
					{...other}
					ref={inputRef}
					mask={numericMask}
					placeholderChar={placeHolderChar}
				/>
			);
		}

		this.NumericInput.propTypes = {
			inputRef: PropTypes.func.isRequired,
		};

		this.MaskedInput = props => {
			const { inputRef, ...other } = props;
			let { Field } = this.props;
			
			return (
				<MaskedInput
  				{...other}
  				autoComplete="off"
  				ref={inputRef}
  				mask={GetRegexMaskFromCapturePointMask(Field.CapturePointMask)}
  				pipe={this.ProcessValue}
  				placeholderChar={placeHolderChar}
				/>
			);
		}

		this.MaskedInput.propTypes = {
			inputRef: PropTypes.func.isRequired,
		};
	}

	ProcessValue = (value, config) => {
		let { Field } = this.props;
		// Modify according to modifier
		let m = GetModifiersFromCapturePointMask(Field.CapturePointMask);
		if (m.length > 0) {
			for (let i = 0; i < m.length; i++) {
				let before = value.substr(0, m[i].position),
					current = value.substr(m[i].position, 1),
					after = value.substr(1 + m[i].position);
				switch (m[i].modifier) {
					 case ">":
					 value = before + (current + after).toUpperCase();
					 break;
					 case "<":
					 value = before + (current + after).toLowerCase();
					 break;
					default:
					value = before + current + after;	
					break;
				}
			}
			// Static characters should always remain static
			let sc = GetStaticCharactersFromCapturePointMask(Field.CapturePointMask);
			if (sc.length > 0) {
				for (let i = 0; i < sc.length; i++) {
					let before = value.substr(0, sc[i].position),
						after = value.substr(1 + sc[i].position);
					value = before + sc[i].character + after;	
				}
			}
		}
		return value;
	}

	handleListSelectionChange = selectedOptions => {
		this.props.onValueChange(null, selectedOptions);
	}

	handleListSelectionAdd = newValue => {
		this.props.onSelectionListItemAdd(newValue);
	}

	handleClearValue = () => {
    this.props.onValueChange("");
  }

	// trySetFocus = () => {
	// 	if (this.props.index === 0) {
	// 		if (this.inputRef !== null) {
	// 			if (typeof this.inputRef.inputElement !== "undefined") {
	// 				try {
	// 					this.inputRef.inputElement.focus();
	// 				}
	// 				catch {}
	// 			}
	// 			else {
	// 				try {
	// 					this.inputRef.focus();
	// 				}
	// 				catch {}
	// 			}
	// 		}
	// 	}
	// }

	// Workaround to enable home/end keys on input instead of options
  // https://github.com/JedWatson/react-select/issues/3562
  handleKeyDown = evt => {
    switch(evt.key){
      case "Home": evt.preventDefault();
        if(evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0,0);
        break;
      case "End": evt.preventDefault();
        const len = evt.target.value.length;
        if(evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len,len);
        break;
      default:
        break;
    }
  };

	componentDidUpdate() {
		console.log(this.props.Field.UniqueId, "Updated");
	}

	componentDidMount() {
		//console.log(this.props.Field.UniqueId, "Mounted");
		//this.trySetFocus();
	}

	render() {
		const { classes, theme, Field, Index, onValueChange, onGetSelectionListFilterPromise } = this.props;
		const { fieldId, NumericInput, MaskedInput, handleListSelectionChange, handleListSelectionAdd } = this;

		const selectStyles = {
	      input: base => ({
	        ...base,
	        color: theme.palette.text.primary,
	        '& input': {
	          font: 'inherit',
	        },
	      }),
	      clearIndicator: base => ({
          ...base,
          cursor: "pointer",
        }),
        dropdownIndicator: base => ({
          ...base,
          cursor: "pointer",
        }),
	    };

    let helperText = null;
    let formHelperTextProps = {};
    let helperTextComponent = null;
    if (Field.RequirementNotMet) {
      helperText = "Required";
      formHelperTextProps = {
        style: {color: "red"},
      };
      helperTextComponent = (<FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>);
    }

    let fieldLabel = Field.Name + ((Field.Required) ? " *" : "");

    let clearInputAdornment = 
    (Field.Value && Field.Value.length > 0) 
      ? (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="clear"
              onClick={this.handleClearValue}
            >
              <ClearIcon style={{fontSize:18}} />
            </IconButton>
          </InputAdornment>
        ) 
      : null;

		switch (Field.Type) {
			case "Numeric":
			case "Currency":
			return (
				<FormControl className={classes.formControl}
					variant="outlined"
					fullWidth>
					<InputLabel htmlFor={fieldId}
						shrink={Field.Value !== undefined && Field.Value.length > 0}>
						{fieldLabel}
					</InputLabel>
					<OutlinedInput
						autoComplete="off"
						label={(Field.Value !== undefined && Field.Value.length > 0) ? fieldLabel : null}
						notched={Field.Value !== undefined && Field.Value.length > 0}
						key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
						autoFocus={Index === 0}
						value={Field.Value}
				  	onChange={onValueChange}
						id={fieldId}
						inputComponent={NumericInput}
						endAdornment={clearInputAdornment}
					/>
						{helperTextComponent}
				</FormControl>
			);
			case "Classification":
			return (
				<NoSsr>
					<AsyncSelect
						autoFocus={Index === 0}
						key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
            	className: classes.textField,
                label: fieldLabel,
                InputLabelProps: {
                shrink: true,
              },
            }}
            loadOptions={onGetSelectionListFilterPromise}
            defaultOptions={true}
            cacheOptions={false}
            isClearable
            components={components}
            value={Field.ListValues}
            onChange={handleListSelectionChange}
            isMulti={false}
            onKeyDown={event => this.handleKeyDown(event)}
        	/>
        </NoSsr>
      );
			case "Text":
			if (typeof Field.CapturePointMask !== "undefined" && Field.CapturePointMask !== null && Field.CapturePointMask !== "") {
				return (
					<FormControl className={classes.formControl}
						fullWidth
						variant="outlined">
						<InputLabel htmlFor={fieldId}
							shrink={Field.Value !== undefined && Field.Value.length > 0}>
							{fieldLabel}
						</InputLabel>
						<OutlinedInput
							key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
							autoFocus={Index === 0}
							label={(Field.Value !== undefined && Field.Value.length > 0) ? fieldLabel : null}
	            notched={Field.Value !== undefined && Field.Value.length > 0}
	            value={Field.Value}
					  	onChange={onValueChange}
							id={fieldId}
							inputComponent={MaskedInput}
							endAdornment={clearInputAdornment}
						/>
						{helperTextComponent}
					</FormControl>
				);
			}
			else if (typeof Field.DisplaySelectionList !== "undefined" && Field.DisplaySelectionList) {
				if (typeof Field.AllowNewSelectionListItems !== "undefined" && Field.AllowNewSelectionListItems) {
					return (
						<NoSsr>
							<AsyncCreatableSelect
								key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
								autoFocus={Index === 0}
		            classes={classes}
		            styles={selectStyles}
		            textFieldProps={{
	            		className: classes.textField,
		                label: fieldLabel,
		                InputLabelProps: {
		                shrink: true,
		              },
		              helperText: helperText,
		              FormHelperTextProps: formHelperTextProps,
		            }}
		            loadOptions={onGetSelectionListFilterPromise}
		            defaultOptions={true}
		            cacheOptions={false}
		            components={components}
		            value={Field.ListValues}
		            onChange={handleListSelectionChange}
		            onCreateOption={handleListSelectionAdd}
		            isClearable={!Field.Required}
		            isMulti={Field.AllowMultipleValues}
		            onKeyDown={event => this.handleKeyDown(event)}
	          	/>
        		</NoSsr>
					);
				}
				else {
					return (
						<NoSsr>
							<AsyncSelect
								key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
								autoFocus={Index === 0}
		            classes={classes}
		            styles={selectStyles}
		            textFieldProps={{
		            	className: classes.textField,
		                label: fieldLabel,
		                InputLabelProps: {
		                shrink: true,
		              },
		              helperText: helperText,
                  FormHelperTextProps: formHelperTextProps,
		            }}
		            loadOptions={onGetSelectionListFilterPromise}
		            defaultOptions={true}
		            cacheOptions={false}
		            components={components}
		            value={Field.ListValues}
		            onChange={handleListSelectionChange}
		            isClearable={!Field.Required}
		            isMulti={Field.AllowMultipleValues}
		            onKeyDown={event => this.handleKeyDown(event)}
	          	/>
		        </NoSsr>
					);
				}
			}
			else {
				return (
					<TextField
						key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
						autoFocus={Index === 0}
						variant="outlined"
						autoComplete="off"
      			label={fieldLabel}
            className={classes.textField}
            value={Field.Value}
			  		onChange={onValueChange}
						id={fieldId}
        		fullWidth
        		helperText={helperText}
            FormHelperTextProps={formHelperTextProps}
            InputProps={{
              endAdornment: clearInputAdornment,
            }}
      		/>
				);
			}
			case "Date":
			if (typeof Field.Value !== "undefined" && Field.Value !== null && Field.Value.length > 4) {
				// Ensure inbound year (format yyyy-MM-dd) is not larger than 9999
				try {
					let date = new Date(Field.Value);
					if (date.getFullYear() > 9999)
						Field.Value = "";
				}
				catch { }
			}
			return (
				<TextField
					key={Field.UpdateId} // This is a hack to force the component to update its list when the value changes
					autoComplete="off"
	        variant="outlined"
	        label={fieldLabel}
					type="date"
					value={Field.Value}
				    onChange={onValueChange}
					id={fieldId}
					className={classes.textField}
					inputProps={{max:"9999-12-31"}}
					InputLabelProps={{ shrink: true, }}
					fullWidth
					helperText={helperText}
	        FormHelperTextProps={formHelperTextProps}
					/>
			);
			default:
			return null;
		}
	}
}

FieldInput.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FieldInput);