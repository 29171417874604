import React, { Component } from 'react';

class NotFound404 extends Component {
  // constructor(props) {
  //   super(props);
  // }

  // componentDidMount() {
  // }
  
  render() {
    return (
      <div style={{textAlign: "center", paddingTop: 100, width: "25%" ,margin: "auto"}}>404 Not Found</div>
    );
  }
}

export default NotFound404;
