import React from 'react';
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';

import AlertDialog from './AlertDialog';

const styles = {
  root: {
    flexGrow: 1,
    position:"fixed",
    top:0,
    left:0,
    width:"100%",
    zIndex:2,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class UiCore extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    DrawerOpen: false,
    AlertDialogDetails: {
        Open: false,
        Title: "",
        Body: "",
      },
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = event => {
    this.props.history.push("/logout");
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = (open) => () => {
    this.setState({
      DrawerOpen: open,
    });
  };

  handleApiError(err, onCloseRedirectPath) {
    // console.log(err.response.status);
    // console.log(err.response.data.message);

    if (typeof err.response === "undefined") {
      err = {
        response: {
          status: 500,
          data: {
          message: err.toString(),
          },
        },
      };
    }

    switch(err.response.status) {
      case 401:
      this.props.history.push("/login"); 
      break;
      case 500:
      this.setState({ 
        AlertDialogDetails: {
          Open: true,
          Title: "There seems to be a problem...",
          Body: err.response.data.message,
          RedirectPath: onCloseRedirectPath,
        }
      });
      break;
      default:
      break;
    }
  }

  closeAlertDialog(redirectPath) {
    this.setState({ AlertDialogDetails: { Open: false }});
    if (redirectPath) {
      this.setState({ redirectPath: redirectPath });
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.apiError !== "undefined" && this.props.apiError
      && prevProps.apiError !== this.props.apiError) {
      this.handleApiError(this.props.apiError);
    }
    if (typeof this.props.alert !== "undefined" && this.props.alert
      && prevProps.alert !== this.props.alert) {
      this.setState({ 
        AlertDialogDetails: {
          Open: true,
          Title: this.props.alert.Title,
          Body: this.props.alert.Body,
        }
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { auth, anchorEl, DrawerOpen, AlertDialogDetails } = this.state;
    const open = Boolean(anchorEl);

    const drawerList = (
      <div className={classes.list}>
        <Button component={Link} to="/">
          <img style={{width: 150, padding: 16}} src="/Paige-logo_272x250.png" alt="Dashboard" />
        </Button>
        <Divider />
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>

        <Divider />

        <List subheader={<ListSubheader component="div">Reporting</ListSubheader>}>
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component={Link} to="/tenants">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Tenants" />
          </ListItem>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
              onClick={this.toggleDrawer(true)}>
              {/* this.props.history.push("/") */}
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {this.props.title}
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem> */}
                  {/* <MenuItem onClick={this.handleClose}>My account</MenuItem> */}
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={DrawerOpen}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {drawerList}
          </div>
        </SwipeableDrawer>

        <AlertDialog
          title={AlertDialogDetails.Title}
          text={AlertDialogDetails.Body}
          open={AlertDialogDetails.Open}
          onClose={() => this.closeAlertDialog()}
        />

      </div>
    );
  }
}

UiCore.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UiCore));