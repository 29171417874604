import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';

import UiCore from './/UiCore';

import UserCard from './UserCard';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import API from './api';

const styles = theme => ({
	// column: {
 //    float: "left",
 //    marginLeft: 16,
 //    // width: "50%",
	// },
  contentContainer:{
    position:"absolute",
    left:0,
    top:64,
    height:"calc(100% - 64px)",
    width:"100%",
  },
});

class Users extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Users: [],
    }
  }

  handleListUsers() {
    API.get("/users")
      .then(resp => { 
        this.setState({ Users: resp.data });
        console.log("finished /users");
      })
      .catch(err => this.setState({ApiError:err}));
  }

  handleCardAction(userKey) {
    if (!userKey)
      return;
    this.props.history.push("/user/" + userKey + "/report")
  }

  componentDidMount() {
  	this.handleListUsers();
  }
  
  render() {
  	const { classes, theme } = this.props;
  	const { 
  		ApiError, 
  		Users, 
  	} = this.state;

		let userCards = [];
    if (Users.length > 0) {
      userCards = Users
      .map(u =>
        <Grid item key={u.Key}>
          <UserCard User={u}
            onCardAction={() => this.handleCardAction(u.Key)}
          >
          </UserCard>
        </Grid>
      );
    }

    return (
      <div>
        <UiCore title="Users"
                apiError={ApiError} />

       	<div className={classes.contentContainer}>
          <Grid container spacing={2} style={{width: "100%",padding:theme.spacing(2)}}>
          {userCards}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, {withTheme: true})(Users));