import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { Redirect } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import API from './api';

import AlertDialog from './AlertDialog';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      AlertDialog: {
        Open: false,
        Title: "",
        Body: "",
      }
    }
  }

  HandleError = err => {
    console.log(err.response.status);
    console.log(err.response.data.message);

    switch(err.response.status) {
      case 401:
      case 500:
      default:
        this.setState({ 
          AlertDialog: {
            Open: true,
            Title: "There seems to be a problem...",
            Body: err.response.data.message,
          }
        });
        break;
    }
  }

  CloseAlertDialog() {
    this.setState({ AlertDialog: { Open: false }})
  }

  handleGoogleLoginResponse = resp => {
    // console.log(resp);

    if (!resp || !resp.credential)
      return;
  
    let googleSignin = {
      IdToken: resp.credential
    }

    API.post("/auth/login", googleSignin)
        .then(resp => {
          if (resp.status === 200) {
            this.setState({ redirectToReferrer: true });
          }
          console.log("finished /auth/login")
        })
        .catch(this.HandleError);
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer === true) {
      return (
        <Redirect to={from} />
      );
    }

    return (
      <Container maxWidth="sm" style={{padding:24}}>
        <Grid container direction="column" spacing={6}>
          <AlertDialog
            title={this.state.AlertDialog.Title}
            text={this.state.AlertDialog.Body}
            open={this.state.AlertDialog.Open}
            onClose={() => this.CloseAlertDialog()}
          />

          <Grid item style={{textAlign:"center"}}>
            <img
              style={{
                width:250,
              }}
              src="/paige-logo_500x460.jpg"
              alt="Paige"
            />
          </Grid>

          <Grid item style={{
            display:"flex",
            justifyContent:"center",
          }}>
            <GoogleLogin
              onSuccess={this.handleGoogleLoginResponse}
              onError={() => {
                console.log('Login Failed');
              }}
              width={300}
              type="standard"
              logo_alignment="center"
              size="large"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Login;
