import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect  } from 'react-router-dom';

import Login from './Components/Login';
import NotFound404 from './Components/NotFound404';
import Dashboard from './Components/Dashboard';
import DocumentEditor from './Components/DocumentEditor';
// import TenantMemberInvitationAccept from './Components/TenantMemberInvitationAccept';
import Users from './Components/Users';
import UserReport from './Components/UserReport';
import Tenants from './Components/Tenants';
import TenantReport from './Components/TenantReport';
import Logout from './Components/Logout';

// import TenantFix from './Components/TenantFix';

import { withCookies } from 'react-cookie';

import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './App.css';

const Authentication = {
  isAuthenticated(cookies) {
    let c = cookies.get("isAuthenticated");
    return c !== undefined && c === "true";
  }
}

const ProtectedRoute = withCookies(({ component: Component, ...rest }) =>
  <Route {...rest} render={(props) => (
    Authentication.isAuthenticated(rest.cookies) === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

const theme = createMuiTheme({
  palette: {
    primary: { main: "#0081ff" },
    secondary: { main: "#00ffdb" },
  },
  // status: {
  //   danger: 'orange',
  // },
  typography: {
    // fontFamily: [
    //   "Roboto",
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   "Segoe UI",
    //   "Arial",
    //   "sans-serif"
    // ].join(","),
    useNextVariants: true
  }
});

class App extends Component {
  render() {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <ProtectedRoute exact path={"/"} component={Dashboard} />
              <Route exact path={"/login"} component={Login} />
              <Route path={"/logout"} component={Logout} />

              <ProtectedRoute path={"/dashboard"} component={Dashboard} />
              <ProtectedRoute path={"/document/:tenantKey"} component={DocumentEditor} />
              {/* <ProtectedRoute path={"/tenantMemberInvitations/acceptance"} component={TenantMemberInvitationAccept} /> */}
              <ProtectedRoute path={"/users"} component={Users} />
              <ProtectedRoute path={"/user/:userKey/report"} component={UserReport} />
              <ProtectedRoute path={"/tenants"} component={Tenants} />
              <ProtectedRoute path={"/tenant/:tenantKey/report"} component={TenantReport} />

              {/* <ProtectedRoute path={"/TenantFix"} component={TenantFix} /> */}

              <Route component={NotFound404} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </GoogleOAuthProvider>
    );
  }
}

export default DragDropContext(HTML5Backend)(App);
