import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';

import UiCore from './/UiCore';

import TenantCard from './TenantCard';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import API from './api';

const styles = theme => ({
	// column: {
 //    float: "left",
 //    marginLeft: 16,
 //    // width: "50%",
	// },
  contentContainer:{
    position:"absolute",
    left:0,
    top:64,
    height:"calc(100% - 64px)",
    width:"100%",
  },
});

class Tenants extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Tenants: [],
    }
  }

  handleListTenants() {
    API.get("/tenants")
      .then(resp => { 
        this.setState({ Tenants: resp.data });
        console.log("finished /tenants");
      })
      .catch(err => this.setState({ApiError:err}));
  }

  handleCardAction(tenantKey) {
    if (!tenantKey)
      return;
    this.props.history.push("/tenant/" + tenantKey + "/report")
  }

  componentDidMount() {
  	this.handleListTenants();
  }
  
  render() {
  	const { classes, theme } = this.props;
  	const { 
  		ApiError,
  		Tenants, 
  	} = this.state;

		let tenantCards = [];
    if (Tenants.length > 0) {
      tenantCards = Tenants
      .map(t =>
        <Grid item key={t.Key}>
          <TenantCard Tenant={t}
            onCardAction={() => this.handleCardAction(t.Key)}
          >
          </TenantCard>
        </Grid>
      );
    }

    return (
      <div>
        <UiCore title="Tenants"
                apiError={ApiError} />

       	<div className={classes.contentContainer}>
          <Grid container spacing={2} style={{width: "100%",padding:theme.spacing(2)}}>
          {tenantCards}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, {withTheme: true})(Tenants));