import React, { Component } from 'react';

class AddDocument extends Component {
  render() {

    const { 
      addIsDisabled,
      handleAddDocumentsForFields,
      handleAddDocumentsForClassifications,
    } = this.props;

    return (
        <div>
            {/* <input  */}
            {/*   value={this.state.newDocumentName} */}
            {/*   type="text"  */}
            {/*   onKeyPress={e => this.handleKeyPress(e)} */}
            {/*   onChange={e => this.setState({ newDocumentName: e.target.value })} /> */}
            <button disabled={addIsDisabled} onClick={() => handleAddDocumentsForClassifications()}>Add Classifications Document</button>
            <button disabled={addIsDisabled} onClick={() => handleAddDocumentsForFields()}>Add Fields Document</button>
            {/* <button disabled={addIsDisabled} onClick={() => handleAddDocuments(5)}>Add Five</button> */}
            {/* <button disabled={addIsDisabled} onClick={() => handleAddDocuments(100)}>Add 100</button> */}
        </div>
    );
  }
}

export default AddDocument;
