import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import UiCore from './/UiCore';

// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import API from './api';

const styles = theme => ({
	// column: {
 //    float: "left",
 //    marginLeft: 16,
 //    // width: "50%",
	// },
  // textField: {
  //   margin: theme.spacing(1),
  // },
  // button: {
  //   margin: theme.spacing(1),
  // },
  // formControl: {
  //   margin: theme.spacing(1),
  // },
  // input: {
  //   display: 'flex',
  //   padding: 0,
  // },
  contentContainer:{
    position:"absolute",
    left:0,
    top:64,
    height:"calc(100% - 64px)",
    width:"100%",
  },
});

class UserReport extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      FromDate: "",
      ToDate: "",
      Report: {},
      ReportText: "",
    }
  }

  handleGenerateReport(fromDateOverride, toDateOverride) {
    API.get(`/users/${this.props.match.params.userKey}/documentStats`,
      { 
        params: {
          fromDate: (typeof fromDateOverride !== "undefined") ? fromDateOverride : this.state.FromDate,
          toDate: (typeof toDateOverride !== "undefined") ? toDateOverride : this.state.ToDate,
        }
      })
      .then(resp => {
        let report = resp.data;
        let reportText = `Items Reviewed: ${report.TotalDocuments};
          Classification Updates: ${report.ClassificationUpdates};
          Field Updates: ${report.FieldUpdates}
          `;
        this.setState({Report: report, ReportText: reportText});
        console.log("finished /users/<userKey>/documentStats");
      })
      .catch(err => this.setState({ApiError:err}));
  }

  componentDidMount() {
    let now = new Date();
    let lastMonth = new Date(new Date().setMonth(now.getMonth(now) - 1));
    let fromDate = lastMonth.getFullYear() + "-" + ("0" + (1 + lastMonth.getMonth())).slice(-2) + "-" + ("0" + lastMonth.getDate()).slice(-2);
    let toDate = now.getFullYear() + "-" + ("0" + (1 + now.getMonth())).slice(-2) + "-" + ("0" + now.getDate()).slice(-2);
    this.setState({
      FromDate: fromDate,
      ToDate: toDate,
    });
    this.handleGenerateReport(fromDate, toDate);
  }
  
  render() {
  	const { classes, theme } = this.props;
    const { 
  		ApiError,
      FromDate,
      ToDate,
      Report,
      ReportText,
  	} = this.state;

    return (
      <div>
        <UiCore title="User Report"
                apiError={ApiError} />

       	<div className={classes.contentContainer}>

          <Grid container spacing={2} direction="column" style={{width:"100%",padding:theme.spacing(2)}}>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="subtitle1" color="textPrimary">{Report.UserName}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">{Report.UserEmail}</Typography>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item>
              <Grid container spacing={3} direction="row">
                <Grid item>
                  <TextField
                  autoFocus
                  label="From Date"
                  type="date"
                  value={FromDate}  
                  onChange={(event) => {this.setState({FromDate:event.target.value})}}
                  id="FromDate"
                  className={classes.textField}
                  inputProps={{max:"9999-12-31"}}
                  InputLabelProps={{ shrink: true, }}
                  fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                  autoFocus
                  label="To Date"
                  type="date"
                  value={ToDate}  
                  onChange={(event) => {this.setState({ToDate:event.target.value})}}
                  id="ToDate"
                  className={classes.textField}
                  inputProps={{max:"9999-12-31"}}
                  InputLabelProps={{ shrink: true, }}
                  fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" className={classes.button}
                    onClick={(event) => this.handleGenerateReport()}>
                    GO
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="body1" color="textPrimary">{ReportText}</Typography>
            </Grid>
          </Grid>
        
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, {withTheme: true})(UserReport));