import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FieldInput from './FieldInput';
import UiCore from './/UiCore';

import API from './api';
import apiMethods from './api-methods';

const styles = theme => ({
  contentContainer:{
    position:"absolute",
    left:0,
    top:64,
    height:"calc(100% - 64px)",
    width:"100%",
  },
  grid: {
    height:"100%",
  },
  button: {
  },
  input: {
    display: 'none',
  },
});

class DocumentEditor extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Document: {},
      DocumentContentUrl: "",
      IsApiCall: false,
    }
  }

  // This adds the local properties "ListValues" and "ListLabels" to all Field objects,
  // which is necessary for selection-list operation.
  updateDocumentState(document, additionalState) {
    if (document.Fields) {
      for (let i = 0; i < document.Fields.length; i++) {
        let field = document.Fields[i];
        if (typeof field.ListValues === "undefined") {
          Object.assign(field, { ListValues: [] });
          Object.assign(field, { ListLabels: [] });
        }
        // ListValues and ListLabels are for selection lists
        field.ListValues = this.getComposedFieldListLabelsAndValues(field);
        // Values must exist as a JSON array
        if (Array.isArray(field.Values)) {
          field.Values = JSON.stringify(field.Values);
        }
      }
    }
    this.setState(Object.assign({ Document: document}, additionalState));
  }

  getComposedFieldListLabelsAndValues(field) {
    // This may need to be updated in the future to use Field.Labels for multi-select fields along with Field.Values.
    // Currently we're using the items in Field.Values for both the label and value of a selection list.
    let values = field.Values;
    if (typeof field.Values === "string" && field.Values.length > 0)
      values = JSON.parse(field.Values);
    return (typeof field.AllowMultipleValues !== "undefined" && field.AllowMultipleValues)
      ? (Array.isArray(values)) ? values.map(v => ({ value: v, label: v })) : null
      : (typeof field.Value === "string") 
        ? (field.Type === "Classification") ? { value: field.Value, label: field.Label } : { value: field.Value, label: field.Value}
        : null;
  }

  loadNextDocument_ResolveCallback(resp) {
    if (resp.status === 204) {
      this.props.history.push("/");
      return;
    }

    let document = {...resp.data};

    let documentContentUrl = "";
    // if (!process.env.REACT_APP_DEBUG) {
      documentContentUrl = process.env.REACT_APP_API_HOST + "/api/v1/documents/" + resp.data.UniqueId + "/content?tenant=" + this.props.match.params.tenantKey;
    // }

    // This is a hack to force all fields to be re-rendered, thus allowing the focus to be set propertly on the first item
    if (document.Fields) {
      for (let i = 0; i < document.Fields.length; i++) {
        document.Fields[i].UpdateId = (new Date()).getTime();
      }
    }

    this.updateDocumentState(document, {IsApiCall: false, DocumentContentUrl: documentContentUrl});
    console.log("finished /documents");
  }

  loadNextDocument(priorDocumentKeyOptional) {
    apiMethods.GetNextAvailableDocument(
      this.props.match.params.tenantKey, 
      (resp) => this.loadNextDocument_ResolveCallback(resp), 
      (err) => this.handleApiError(err),
      priorDocumentKeyOptional);
  }

  handleDocumentUpdate(commit, abort, commitAsSupportingPage) {
    let document = this.state.Document;
    let tenantKey = this.props.match.params.tenantKey;
    this.setState({IsApiCall: true});
    API.put("/documents/" + document.Key, document,
      { 
        params: { commit: commit, abort: abort, commitAsSupportingPage: commitAsSupportingPage, tenant: tenantKey }
      }
    )
      .then(resp => {
        if (commit || commitAsSupportingPage) {
          this.loadNextDocument(document.Key);
        }
        else if (abort) {
         this.props.history.push("/");
        }
        console.log("finished PUT /documents");
      })
      .catch(err => this.handleApiError(err));  
  }

  getParentFieldValue(fieldUniqueId) {
    let document = this.state.Document;
    let currentField = document.Fields.filter(f => f.UniqueId === fieldUniqueId)[0];
    let parentValue = "";
    if (currentField.ParentUniqueId) {
      let parentField = document.Fields.filter(f => f.UniqueId === currentField.ParentUniqueId)[0];
      parentValue = parentField.Value;
    }
    return parentValue;
  }

  handleSelectionListFilter(fieldUniqueId, fieldType, input, parentValue) {
    let tenantKey = this.props.match.params.tenantKey;

    // If Field.ParentUniqueId has a value, we need to extract the value (if not provided as argument) from the parent and pass along
    if (typeof parentValue === "undefined" || parentValue === null) {
      parentValue = this.getParentFieldValue(fieldUniqueId);
    }
    //console.log("handleSelectionListFilter", fieldUniqueId, fieldType, input, parentValue);

    let valueFilter = (fieldType === "Classification") ? "" : input;
    let labelFilter = (fieldType === "Classification") ? input : "";

    return API.get("/selectionLists/" + fieldUniqueId + "/items",
      { 
        params: { valueFilter: valueFilter, labelFilter: labelFilter, parentValue: parentValue, tenant: tenantKey }
      }
    )
      .then(resp => {
        console.log("finished GET /selectionLists/" + fieldUniqueId + "/items");
        if (!resp.data) {
          return null;
        }
        return resp.data.map(item => { return (!item.Label) ? ({ value: item.Value, label: item.Value}) : ({ value: item.Value, label: item.Label})});
      })
      .catch(err => this.handleApiError(err));  
  }

  handleSelectionListItemAdd(fieldKey, newValue) {
    let tenantKey = this.props.match.params.tenantKey;
    return API.post("/selectionLists/" + fieldKey + "/items",
      [{ Value: newValue }],
      { 
        params: { tenant: tenantKey }
      }
    )
      .then(resp => {
        this.handleFieldValueChange(fieldKey, null, { value: newValue, label: newValue });
        console.log("finished POST /selectionLists/<id>/items");
      })
      .catch(err => this.handleApiError(err));  
  }

  handleFieldValueChange(uniqueId, event, selectedOptions) {
    let document = {...this.state.Document};
    let field = document.Fields.filter(f => f.UniqueId === uniqueId)[0];
    if (typeof field.DisplaySelectionList != "undefined" && field.DisplaySelectionList && selectedOptions != null) {
      if (field.AllowMultipleValues) {
        field.Labels = selectedOptions.map(o => o.label);
        field.Values = selectedOptions.map(o => o.value);
      } else {
        field.Label = selectedOptions.label;
        field.Value = selectedOptions.value;
      }
    } else {
      if (!event) {
        field.Label = "";
        field.Value = "";
      } else {
        field.Value = event.target.value;
      }
    }
    
    // We need to clear any fields that are selection lists and a child of the current field
    this.clearAncestryValues(document, uniqueId);

    this.updateDocumentState(document);
  }

  clearAncestryValues(document, uniqueId) {
    let childFields = document.Fields.filter(f => f.ParentUniqueId === uniqueId);
    for (let i = 0; i < childFields.length; i++) {
      let childField = childFields[i];
      if (childField.DisplaySelectionList) {
        childField.Label = "";
        childField.Value = "";
        // This is a hack to force the field component (Async Select) to update its list when this UpdateId value changes
        childField.UpdateId = (new Date()).getTime();

        this.clearAncestryValues(document, childField.UniqueId);
      }
    }
  }

  componentDidMount() {
    this.loadNextDocument();
  }

  handleApiError(err) {
    this.setState({ApiError: err, IsApiCall: false});
  }
  
  render() {
    const { classes, theme } = this.props;
    const { Document, DocumentContentUrl, ApiError, Alert, IsApiCall } = this.state;
    
    let fields = [];
    if (Document !== null && typeof Document.Fields !== "undefined" && Document.Fields !== null && Document.Fields.length > 0) {
      let index = -1;
      fields = Document.Fields.map(field => {
        index++;
        return ( 
          <div key={`field_${field.UniqueId}`} style={{paddingTop:theme.spacing(3)}}>
            <FieldInput Field={field}
              Index={index}
              onValueChange={(value, selectedOptions) => this.handleFieldValueChange(field.UniqueId, value, selectedOptions)}
              onSelectionListItemAdd={newValue => this.handleSelectionListItemAdd(field.UniqueId, newValue)}
              onGetSelectionListFilterPromise={input => this.handleSelectionListFilter(field.UniqueId, field.Type, input)}
              />
          </div>
        );
      });
    }

    let PageTitle = ((Document.JobName) ? Document.JobName : "Document Editor")
      + ((Document.PageNumber > 0) ? " (Page " + Document.PageNumber + ")" : "");

    let abortbuttonGridItemSize = (Document.ForBoundary) ? 12 : 6;
    let commitButtonLabel = (Document.EventType === "Classifications" && Document.ForBoundary) ? "BOUNDARY" : "COMMIT";
    let buttonGridItems = [];
    buttonGridItems.push(
      <Grid item xs={6} key="button_COMMIT">
        <Button fullWidth variant="contained" disabled={IsApiCall} color="primary" className={classes.button}
          onClick={() => this.handleDocumentUpdate(true, false, false)}>
          {commitButtonLabel}
        </Button>
      </Grid>  
    );
    if (Document.ForBoundary) {
      // This is the same as COMMIT, but it was requested to prevent confusion
      buttonGridItems.push(
        <Grid item xs={6} key="button_COMMIT2">
          <Button fullWidth variant="contained" disabled={IsApiCall} color="primary" className={classes.button}
            onClick={() => this.handleDocumentUpdate(false, false, true)}>
            SUPPORTING
          </Button>
        </Grid>  
      );
    }
    buttonGridItems.push(
      <Grid item xs={abortbuttonGridItemSize} key="button_ABORT">
        <Button fullWidth variant="contained" disabled={IsApiCall} color="primary" className={classes.button}
          onClick={() => this.handleDocumentUpdate(false, true, false)}>
          ABORT
        </Button>
      </Grid>
    );


    return (
      <div style={{height:"100%"}}>
        <UiCore title={PageTitle}
          apiError={ApiError}
          alert={Alert} />
        <div className={classes.contentContainer}>
          <Grid container direction="row" className={classes.grid}>
            <Grid item xs={3} style={{height:"100%",overflow:"auto",padding:theme.spacing(3),paddingTop:0}}>
              <div>
                {fields}
                <div>
                  <Grid container direction="row" spacing={2} style={{paddingTop:theme.spacing(3)}}>
                    {buttonGridItems}
                  </Grid>
                </div>
              </div>
            </Grid>
          
            <Grid item xs={9} style={{height:"100%"}}>
              <object
                data={DocumentContentUrl}
                type="application/pdf"
                style={{width:"100%",height:"100%"}}>
                PDF
              </object>
            </Grid>
        
          </Grid>
        </div>
      </div>
    );
  }
}

DocumentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles,{withTheme: true})(DocumentEditor));